import { Context } from '@nuxt/types';
import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { ECountryCodeISO31661 } from '@/domain/core/Country.enum';
import { AllCookies } from '@/domain/core/http/Cookie.enum';
import { EHeader } from '@/domain/core/http/Header';
import { ERoot as MutationType } from '@/domain/core/store/MutationType.enum';
import { EUserRole } from '@/domain/user/types';
import { UserState } from '@/infrastructure/core/store/modules/User.interface';
import { accessorType } from '@/store';

;

export const state = (): UserState => ({
  authenticated: false,
  email: '',
  firstName: '',
  frenchPostalCode: null,
  hashedUser: '',
  id: '',
  isPro: false,
  legacyId: null,
  roles: [],
  shopId: null,
  token: '',
});

export const getters = getterTree(state, {
  hasB2BProEmail: ({ email }): boolean => /^(?=.*pro+)(?=.*selency).*$/.test(email),
  isAdmin: ({ roles }): boolean => roles.includes(EUserRole.Admin),
  isB2BPro: ({ roles }): boolean => roles.includes(EUserRole.B2BPro),
  isExpert: ({ roles }): boolean => roles.includes(EUserRole.Expert),
  frenchDepartmentNumber: ({ frenchPostalCode }): string | null => frenchPostalCode?.slice(0, 2) || null,
});

export const mutations = mutationTree(state, {
  [MutationType.SET_USER]: (state, payload: Partial<UserState>): void => {
    Object.assign(state, { ...state, ...payload });
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchUser(): Promise<void> {
    try {
      const { userId: id, userToken: token } = this.$cookies.getAll<AllCookies>(); // Cookies are the source of truth.

      if (!token || !id) {
        return;
      }

      const accessor: typeof accessorType = this.app.$accessor;

      accessor.user.SET_USER({ id, token }); // Both data are needed to perfom API calls.

      const user = await this.$repositories.user.get(id);

      accessor.user.SET_USER({
        ...user,
        authenticated: true,
        token,
      });
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
  setPostalCode(_, requestHeaders: Context['req']['headers']): void {
    const isLocatedInFrance = requestHeaders?.[EHeader.CloudfrontUserCountryCode] === ECountryCodeISO31661.FR;

    if (!isLocatedInFrance) {
      return;
    }

    const postalCode = requestHeaders?.[EHeader.CloudfrontUserPostalCode];

    if (!postalCode || Array.isArray(postalCode) || postalCode.length < 2) {
      return;
    }

    const accessor: typeof accessorType = this.app.$accessor;

    accessor.user.SET_USER({ frenchPostalCode: postalCode });
  },
});
