var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-wrap items-center gap-y-1",class:{
    'text-tiny': _vm.isCompact,
    'text-base leading-5 lg:text-lg lg:leading-5': !_vm.isCompact,
  }},[_c('div',{staticClass:"inline-flex pb-0.5"},[(_vm.specialPrice)?_c('ProductPriceTag',{class:{
        'text-terracotta': !_vm.isUserPro,
      },attrs:{"price":_vm.specialPrice,"should-enable-microdata":_vm.shouldEnableMicrodata}}):_vm._e(),_vm._v(" "),_c('ProductPriceTag',{class:{
        'ml-2': _vm.specialPrice
      },attrs:{"is-old-price":!!_vm.specialPrice,"price":_vm.price,"should-enable-microdata":_vm.shouldEnableMicrodata}})],1),_vm._v(" "),_c('div',{class:{
      'text-xxxs leading-3': _vm.isCompact,
      'text-xs leading-[14px]': !_vm.isCompact,
      'ml-2': _vm.hasProPrice || _vm.shouldDisplayHandmadeBadge || _vm.shouldDisplayNegotiableBadge,
    },attrs:{"data-testid":"product-price-badge-wrapper"}},[(_vm.hasProPrice)?_c('BadgeProPrice'):(_vm.shouldDisplayHandmadeBadge)?_c('BadgeHandmade'):(_vm.shouldDisplayNegotiableBadge)?_c('BadgeNegotiable'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }