import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { EEmbedsName, Embeds } from '@/domain/core/api/embeds.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SelectionRepositoryInterface } from '@/domain/selection/selectionRepository.interface';
import { ESelection, Selection } from '@/domain/selection/types';
import { transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const resourcePath = '/selections';

export default class SelectionRepository implements SelectionRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;

  constructor(httpService: HttpService, basePath: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
  }

  async getSelection(slug: `${ESelection}`): Promise<Selection> {
    const { content } = await this.#httpService.request<ApiBase<Selection, Pick<Embeds, EEmbedsName.Products>>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}`,
      queryParams: {
        slug,
        embeds: EEmbedsName.Products,
      },
    });

    const [selection] = transformList(content?.items) || [];

    return selection;
  }
}
