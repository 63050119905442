export enum EFeatureFlags {
  CraftmanshipHandmadeHomepageBanner = 'craftmanship-handmade-homepage-banner-web',
  CraftmanshipHandmadeItems = 'craftmanship-handmade-items-web',
  CraftmanshipHandmadeProductPageMarketingMessage = 'craftmanship-handmade-product-page-marketing-message-web',
  CrmHeaderAppDownloadButton = 'crm-header-app-download-button',
  CrmProductPageAppDownloadButton = 'crm-product-page-app-download-button',
  HelpBannerCustomerCare = 'help-banner-customer-care',
  HomepageProBanner = 'homepage-pro-banner-web',
  OneDayPayment = 'feature-one-day-payment',
  ReviewsInternalizeCollection = 'reviews-internalize-collection',
  Sales = 'sales-web',
  SearchProductsWithinAutocompleteSuggestions = 'search-product-suggestions-within-search-panel',
  SearchRecentSearchesSuggestions = 'search-recent-searches-suggestions',
}

export type FeatureFlagsState = Record<`${EFeatureFlags}`, boolean>;

export interface LaunchDarklyLink {
  href: string;
  type: string;
}

export interface Target {
  contextKind: string;
  values: string[];
  variation: number;
}

export interface LaunchDarklyEnvironment {
  _environmentName: string;
  _site: {
    href: string;
    type: string;
  };
  _summary: {
    prerequisites: number;
    variations: Record<string, any>;
  };
  archived: boolean;
  lastModified: number;
  offVariation: number;
  on: boolean;
  salt: string;
  sel: string;
  targets: Target[];
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  version: number;
}

// https://apidocs.launchdarkly.com/tag/Feature-flags/#operation/getFeatureFlags
export interface LaunchDarklyApiItem {
  _links: {
    parent: LaunchDarklyLink;
    self: LaunchDarklyLink;
  };
  _maintainer: {
    _id: string;
    _links: {
      self: LaunchDarklyLink;
    };
    email: string;
    role: string;
  };
  _version: number;
  archived: boolean;
  clientSideAvailability: {
    usingEnvironmentId: boolean;
    usingMobileKey: boolean;
  };
  creationDate: number;
  customProperties: {};
  description: string;
  environments: Record<string, LaunchDarklyEnvironment>;
  experiments: {
    baselineIdx: number;
    items: unknown[];
  };
  goalIds: string[];
  includeInSnippet: string;
  key: `${EFeatureFlags}`;
  kind: boolean;
  maintainerId: string;
  name: string;
  tags: string[];
  temporary: boolean;
  variations:
    {
      _id: string;
      value: boolean;
    }[];
}
