import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { CurrencyRepositoryInterface } from '@/domain/pricing/currencyRepository.interface';
import { CurrencyRate } from '@/domain/pricing/types';
import { transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

export default class CurrencyRepository implements CurrencyRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;

  constructor(httpService: HttpService, basePath: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
  }

  async getCurrencyRates(): Promise<CurrencyRate[]> {
    const { content } = await this.#httpService.request<ApiBase<CurrencyRate>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/currency-rates`,
    });

    return transformList(content?.items);
  }
}
