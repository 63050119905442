
import Vue from 'vue';

import { EGtmViewModal } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'ModalQRCodeAppDownload',
  mounted() {
    this.$gtm.push({ event: EGtmViewModal.QRCodeDesktopAppDownload });
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
  },
});
