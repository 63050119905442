
import Vue, { PropType } from 'vue';
import { Component } from 'vue/types/umd';

import IcCheck from '@/assets/svg/ic_check.svg?inline';
import { NavigationStepLink } from '@/infrastructure/core/components/navigation/NavigationStepLink.interface';

interface LocalComponentType {
  name: string;
  props: Record<string, string | boolean>;
}

export default Vue.extend({
  name: 'NavigationStepLink',
  components: {
    IcCheck,
  },
  props: {
    step: {
      type: Object as PropType<NavigationStepLink>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType(): LocalComponentType {
      let component: LocalComponentType = {
        name: 'BaseLink',
        props: {
          href: this.step.href,
          isUnstyled: true,
        },
      };

      if (this.isDisabled) {
        component = {
          name: 'span',
          props: {},
        };
      }

      return component;
    },
    iconName(): Component {
      return this.isChecked ? IcCheck : this.step.icon;
    },
  },
});
