export enum ESchema {
  AggregateRating = 'https://schema.org/AggregateRating',
  BreadcrumbList = 'https://schema.org/BreadcrumbList',
  LimitedAvailability = 'https://schema.org/LimitedAvailability',
  ListItem = 'https://schema.org/ListItem',
  Offer = 'https://schema.org/Offer',
  Product = 'https://schema.org/Product',
  SoldOut = 'https://schema.org/SoldOut',
  Thing = 'https://schema.org/Thing',
}

export enum ESchemaListItem {
  ItemListElement = 'itemListElement',
}

export enum ESchemaOffer {
  Availability = 'availability',
  Price = 'price',
  PriceCurrency = 'priceCurrency',
}

export enum ESchemaProduct {
  AggregateRating = 'aggregateRating',
  Image = 'image',
  Name = 'name',
  Offers = 'offers',
}

export enum ESchemaThing {
  Item = 'item',
  Name = 'name',
  Position = 'position',
}

export enum ESchemaAggregateRating {
  RatingValue = 'ratingValue',
  ReviewCount = 'reviewCount',
}

export enum ESchemaType {
  AggregateRating = 'AggregateRating',
  Offer = 'Offer',
  Organization = 'Organization',
  Product = 'Product',
  SearchAction = 'SearchAction',
  Website = 'WebSite',
}
