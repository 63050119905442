import { CookieValue } from 'cookie-universal-nuxt';

export enum ECookie {
  CsrfToken = 'csrfToken',
  Currency = 'currency',
  I18nRedirected = 'i18n_redirected',
  Language = 'language',
  NewsletterModalDismissed = 'accept_newsletter', // NOTE: Legacy cookie - its value doesn't matter.
  UserCartId = 'userCartId',
  UserCurrentOrderId = 'userCurrentOrderId',
  UserId = 'userId',
  UserSegmentId = 'userSegmentId',
  UserToken = 'userToken',
}

export type AllCookies = Record<`${ECookie}`, CookieValue>;

export enum ECookieMaxAge {
  OneDay = 60 * 60 * 24,
  MaxAllowedByGDPR = ECookieMaxAge.OneDay * 395,
  OneMonth = ECookieMaxAge.OneDay * 30,
  SixMonths = ECookieMaxAge.OneMonth * 6,
  TenDays = ECookieMaxAge.OneDay * 10,
  TwoDays = ECookieMaxAge.OneDay * 2,
}
