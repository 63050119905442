export enum EStaticAsset {
  HandmadeCategories = 'handmade-categories',
  PopularSearches = 'popular-searches',
}

export interface HandmadeCategory {
  imageId: string;
  title: string;
  url: string;
}

export interface SearchQuery {
  imageId: string;
  query: string;
}
