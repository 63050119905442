
import Vue from 'vue';

const MAX_COUNT = 9;

export default Vue.extend({
  name: 'NotificationDot',
  props: {
    count: {
      type: Number,
      default: 0,
      validator: (value: number): boolean => value >= 0,
    },
    positionModifiers: {
      type: String,
      default: '',
    },
  },
  computed: {
    fmtCount(): string | number {
      if (this.count > MAX_COUNT) {
        return `${MAX_COUNT}+`;
      }

      return this.count;
    },
    isDisplayable(): boolean {
      return this.count > 0;
    },
  },
});
