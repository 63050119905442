
import Vue from 'vue';

import IcCrossSmall from '@/assets/svg/ic_cross_small.svg?inline';

export default Vue.extend({
  name: 'BaseModal',
  components: {
    IcCrossSmall,
  },
  props: {
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    shouldHideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousElement: null as HTMLElement | null, // Allow us to focus again the trigger button once the modal is closed.
    };
  },
  computed: {
    isBackdropVisible(): boolean {
      return this.$accessor.ui.backdrop.isVisible;
    },
  },
  watch: {
    // Component listens to the store's backdrop visibility changes and closes modal if invisible.
    isBackdropVisible(newValue): void {
      if (!newValue) {
        this.closeModal();
      }
    },
  },
  // Mounting it displays the backdrop. No choice, deal with it. 👀
  mounted() {
    this.capturePreviousElement((document?.activeElement as HTMLElement) || document?.body);
    this.focusCurrentModal();

    this.$accessor.ui.SHOW_BACKDROP();
  },
  // Unmounting it hides the backdrop.
  destroyed() {
    this.$accessor.ui.closeDialog();

    this.focusPreviousElement();
    this.capturePreviousElement(null);
  },
  methods: {
    capturePreviousElement(element: HTMLElement | null): void {
      this.previousElement = element;
    },
    // Alert parent that the close button or the backdrop have been clicked on.
    closeModal(): void {
      this.$emit('close');
    },
    focusCurrentModal(): void {
      const modalElement = (this.$refs.baseModal as HTMLElement);

      this.focusElement(modalElement);
    },
    focusElement(element?: HTMLElement | null): void {
      if (element) {
        element.focus();
      }
    },
    focusPreviousElement(): void {
      this.focusElement(this.previousElement);
    },
  },
});
