import { Category, Product, ProductPricing, Story } from '@/domain/product/types';
import { ShopDetails, ShopStats } from '@/domain/shop/types';
import { UserPublic } from '@/domain/user/types';

export enum EEmbedsName {
  Buyer = 'buyer',
  Categories = 'categories',
  Category = 'category',
  Pricing = 'pricing',
  Products = 'products',
  Seller = 'seller',
  Shop = 'shop',
  Statistics = 'statistics',
  Story = 'story',
  User = 'user',
}

export interface Embeds {
  [EEmbedsName.Buyer]: UserPublic;
  [EEmbedsName.Seller]: UserPublic;
  [EEmbedsName.Categories]: Category[];
  [EEmbedsName.Category]: Category;
  [EEmbedsName.Pricing]: ProductPricing;
  [EEmbedsName.Products]: Product[];
  [EEmbedsName.Shop]: ShopDetails;
  [EEmbedsName.Statistics]: ShopStats;
  [EEmbedsName.Story]: Story;
  [EEmbedsName.User]: UserPublic;
}
